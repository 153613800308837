import React, { useState, useContext, useRef } from "react";

import { Colors } from "../../commons/Theme";

import { countries } from "../../commons/Countries";
import Icon, { icons } from "../Icon/Icon";
import {
  StyledPaymentDetailAddressTitle,
  StyledPaymentDetailChooserCTAButtons,
  StyledPaymentDetailChooserCTAButton,
  StyledPaymentDetailChoosersHiveFilterCheckbox,
  StyledPaymentDetailChooserItemFilterInput,
  StyledPaymentDetailSummaryEditorial,
  StyledPaymentDetailSummaryEditorialForm,
  StyledPaymentDetailSummaryEditorialFormBlock,
  StyledPaymentDetailSummaryEditorialFormInput,
  StyledPaymentDetailSummaryEditorialFormInputWithError,
  StyledPaymentDetailTooltip,
  StyledPaymentDetailTooltipTrigger,
  StyledPaymentDetailBigTitle,
  StyledPaymentDetailSummaryEditorialFormAnchor,
} from "./style";

import { PaymentContext } from "./PaymentContext";
import { toast } from "react-toastify";
import useApi from "../../hooks/useApi";
import { StyledFormSelect } from "../../commons/Form";

export default ({ validation }) => {

  // CONTEXTS
  const {
    labels,
    setShowForm,
    setIsLoading,
    setUserExist,
    setAccessToken,
    setUserAuthToken,
    userInfo,
    setUserInfo,
    emailRegex,
  } = useContext(PaymentContext);
  const formRef = useRef(null);
  const { createUser, checkIfUserExist } = useApi();

  let typingTimer = null;
  let switchFormTimer = null;
  const [skipAddressCheck, setSkipAddressCheck] = useState(false);
  const [skipAddress, setSkipAddress] = useState(false);
  const isEmailValid = () => {
    return formData && formData.email && emailRegex.test(formData.email);
  };
  const isFullnameValid = () => {
    return formData && formData.fullname && formData.fullname.trim().split(" ").length >= 2;
  };
  const isAddressValid = () => {
    if (skipAddress)
      return true;
    return formData && formData.street1 && formData.street_number && formData.state && formData.city && formData.country && formData.zip;
  };
  const canRegisterUser = () => {
    return isEmailValid() && isFullnameValid() && isAddressValid();
  };
  const getErrorMessage = (errorInfo) => {
    const errorKey = errorInfo.error;
    const translationKey = `ADOPTION_FORM_${errorKey}`;

    let errorMessage;
    if (translationKey in labels) {
      errorMessage = labels[translationKey];
    } else {
      errorMessage = errorInfo.message;
    }

    if (errorKey === "ADDRESS_NOT_VERIFIED") {
      return `${labels.ADOPTION_FORM_ADDRESS_SUGGESTION} ${errorMessage}`;
    }
    return errorMessage;
  };
  const performSignup = () => {
    if (validation && validation()) {
      return;
    }

    if(formRef.current && !formRef.current.reportValidity()) {
      return;
    }

    toast.configure();
    if (!canRegisterUser()) {
      toast.error("Compila tutti i campi necessari e/o correggi gli eventuali errori per proseguire", {
        containerId: "Alert",
        position: toast.POSITION.TOP_CENTER, 
      });
      return;
    }
    setIsLoading(true);
    createUser(formData, skipAddress).then((res) => {
      setIsLoading(false);
      if (res && res.error) {
        toast.error("Errore, non è stato possibile creare l'utente", {
          containerId: "Alert",
          position: toast.POSITION.TOP_CENTER, 
        });
        const errorMessage = res.message;

        let newFormError = {};
        if (errorMessage.error === "VALIDATION_ERROR" && errorMessage.details) {
          for (const [key, value] of Object.entries(errorMessage.details)) {
            newFormError[key] = value;
            if (key === "address" && Object.values(value).find((el) => el[0].error === "ADDRESS_NOT_VERIFIED")) {
              setSkipAddressCheck(true);
            }
            if (key === "email" && value.find((el) => el.error === "UNIQUE")) {
              setUserInfo({
                ...userInfo,
                email: formData.email, 
              });
              clearTimeout(switchFormTimer);
              switchFormTimer = setTimeout(function () {
                // The user exists, switch to the login
                setUserExist(true);
              }, 1250);

            }
          }
        }
        setFormError(newFormError);
      } else {
        toast.success("Utente creato con successo!", {
          containerId: "Alert",
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000, 
        });
        setUserAuthToken(res.data.data);
        setAccessToken(res.data.data.access);
        setShowForm(false);
      }
    });
  };
  const [formData, setFormData] = useState({
    locale: "it",
    email: "",
    fullname: "",
    street1: "",
    street_number: "",
    zip: "",
    state: "",
    country: "Italy",
    city: "",
    street2: "",
    force: false,
  });
  const [formError, setFormError] = useState({
    locale: null,
    email: null,
    fullname: null,
    address: {
      street1: null,
      street_number: null,
      zip: null,
      state: null,
      country: null,
      city: null,
      street2: null,
    },
  });

  return (
    <>
      <StyledPaymentDetailSummaryEditorial>
        <StyledPaymentDetailSummaryEditorialForm Validate>
          <StyledPaymentDetailBigTitle>
            I tuoi dati personali
          </StyledPaymentDetailBigTitle>
          <form ref={formRef}>
            <StyledPaymentDetailSummaryEditorialFormBlock>
              <StyledPaymentDetailSummaryEditorialFormInput
                type="email"
                placeholder="La tua Email *"
                required
                value={formData.email}
                onChange={(e) => {
                  const val = e.currentTarget.value;

                  setFormData({
                    ...formData,
                    email: e.currentTarget.value, 
                  });
                  clearTimeout(typingTimer);
                  typingTimer = setTimeout(() => {
                    if (val && emailRegex.test(val)) {
                      checkIfUserExist(val).then((res) => {
                        if (res && res.error) {
                          const errorMessage = res.message;

                          let newFormError = { ...formError };
                          if (errorMessage.error === "VALIDATION_ERROR" && errorMessage.details) {
                            for (const [key, value] of Object.entries(errorMessage.details)) {
                              newFormError[key] = value;
                              if (key === "email" && value.find((el) => el.error === "UNIQUE")) {
                                clearTimeout(switchFormTimer);
                                switchFormTimer = setTimeout(function () {
                                  setUserInfo({
                                    ...userInfo,
                                    email: val, 
                                  });
                                  setUserExist(true);
                                }, 1500);

                              }
                            }
                          }
                          setFormError(newFormError);
                        } else {
                          let newFormError = { ...formError };
                          newFormError.email = null;
                          setFormError(newFormError);
                        }
                      });

                    }
                  }, 750);
                }}
              />
              <span>
                Verrà usata questa email per creare il tuo account
                personale!
              </span>
              {formData.email && !emailRegex.test(formData.email)
                ? <span style={{ color: "red" }}>
                  Inserisci una email in un formato valido!
                </span> : null}
              {formError.email && formError.email.map(
                (error, index) => (
                  <span key={index} style={{ color: "red" }}>
                    {getErrorMessage(error)}
                  </span>))
              }
            </StyledPaymentDetailSummaryEditorialFormBlock>
            <StyledPaymentDetailSummaryEditorialFormBlock>
              <StyledPaymentDetailSummaryEditorialFormInput
                type="text"
                placeholder="Nome e cognome *"
                required
                value={formData.fullname}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    fullname: e.currentTarget.value, 
                  });
                  let newFormError = { ...formError };
                  newFormError.fullname = null;
                  setFormError(newFormError);
                }}
              />
              {formData.fullname && formData.fullname.trim().split(" ").length < 2
                ? <span style={{ color: "red" }}>
                  Inserisci nome e cognome separati da spazio!
                </span> : null}
              {formError.fullname && formError.fullname.map(
                (error, index) => (
                  <span key={index} style={{ color: "red" }}>
                    {getErrorMessage(error)}
                  </span>))
              }
            </StyledPaymentDetailSummaryEditorialFormBlock>

            <StyledPaymentDetailAddressTitle>
              <div>Indirizzo di spedizione{" "}<StyledPaymentDetailTooltip>
                <StyledPaymentDetailTooltipTrigger>
                  ?
                </StyledPaymentDetailTooltipTrigger>
              </StyledPaymentDetailTooltip></div>

              <StyledPaymentDetailChoosersHiveFilterCheckbox
                onClick={() => {
                  const newValue = !skipAddress;

                  setSkipAddress(newValue);
                  if (newValue) {
                    let newFormError = { ...formError };
                    newFormError.address = {};
                    setFormError(newFormError);
                    setFormData({
                      ...formData,
                      street1: "",
                      street_number: "",
                      zip: "",
                      state: "",
                      country: "",
                      city: "",
                      street2: "",
                      force: false,
                    });
                  }
                }}
              >
                Non compilare indirizzo di spedizione ora
                {skipAddress && (
                  <Icon icon={icons.check} color={Colors.yellow} />
                )}
              </StyledPaymentDetailChoosersHiveFilterCheckbox>
            </StyledPaymentDetailAddressTitle>

            {!skipAddress
              ? <>
                <StyledPaymentDetailSummaryEditorialFormBlock>
                  {formError.address && formError.address.non_field_errors && formError.address.non_field_errors.map(
                    (error, index) => (
                      <span key={index} style={{ color: "red" }}>
                        {getErrorMessage(error)}
                      </span>))
                  }
                  <StyledPaymentDetailSummaryEditorialFormInputWithError>
                    <StyledPaymentDetailSummaryEditorialFormInput
                      type="text"
                      placeholder="Via/Vicolo/Piazza"
                      required
                      value={formData.street1}
                      maxLength="35"
                      size="35"
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          street1: e.currentTarget.value, 
                        });
                        let newFormError = { ...formError };
                        newFormError.address.street1 = null;
                        setFormError(newFormError);
                      }}
                    />
                    {formError.address && formError.address.street1 && formError.address.street1.map(
                      (error, index) => (
                        <span key={index} style={{ color: "red" }}>
                          {getErrorMessage(error)}
                        </span>))
                    }
                  </StyledPaymentDetailSummaryEditorialFormInputWithError>
                  <StyledPaymentDetailSummaryEditorialFormInputWithError isSmall>
                    <StyledPaymentDetailSummaryEditorialFormInput
                      type="text"
                      placeholder="N. civico"
                      required
                      isSmall
                      value={formData.street_number}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          street_number: e.currentTarget.value, 
                        });
                        let newFormError = { ...formError };
                        newFormError.address.street_number = null;
                        setFormError(newFormError);
                      }}
                    />
                    {formError.address && formError.address.street_number && formError.address.street_number.map(
                      (error, index) => (
                        <span key={index} style={{ color: "red" }}>
                          {getErrorMessage(error)}
                        </span>))
                    }
                  </StyledPaymentDetailSummaryEditorialFormInputWithError>
                  <StyledPaymentDetailSummaryEditorialFormInputWithError isMid>
                    <StyledPaymentDetailSummaryEditorialFormInput
                      type="text"
                      placeholder="Città"
                      required
                      isMid
                      value={formData.city}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          city: e.currentTarget.value, 
                        });
                        let newFormError = { ...formError };
                        newFormError.address.city = null;
                        setFormError(newFormError);
                      }}
                    />
                    {formError.address && formError.address.city && formError.address.city.map(
                      (error, index) => (
                        <span key={index} style={{ color: "red" }}>
                          {getErrorMessage(error)}
                        </span>))
                    }
                  </StyledPaymentDetailSummaryEditorialFormInputWithError>
                  <StyledPaymentDetailSummaryEditorialFormInputWithError isSmall>
                    <StyledPaymentDetailSummaryEditorialFormInput
                      type="text"
                      placeholder="Provincia"
                      required
                      isSmall
                      value={formData.state}
                      maxLength="2"
                      size="2"
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          state: e.currentTarget.value, 
                        });
                        let newFormError = { ...formError };
                        newFormError.address.state = null;
                        setFormError(newFormError);
                      }}
                    />
                    {formError.address && formError.address.state && formError.address.state.map(
                      (error, index) => (
                        <span key={index} style={{ color: "red" }}>
                          {getErrorMessage(error)}
                        </span>))
                    }
                  </StyledPaymentDetailSummaryEditorialFormInputWithError>
                  <StyledPaymentDetailSummaryEditorialFormInputWithError isSmall>
                    <StyledPaymentDetailSummaryEditorialFormInput
                      type="text"
                      placeholder="CAP"
                      required
                      isSmall
                      value={formData.zip}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          zip: e.currentTarget.value, 
                        });
                        let newFormError = { ...formError };
                        newFormError.address.zip = null;
                        setFormError(newFormError);
                      }}
                    />
                    {formError.address && formError.address.zip && formError.address.zip.map(
                      (error, index) => (
                        <span key={index} style={{ color: "red" }}>
                          {getErrorMessage(error)}
                        </span>))
                    }
                  </StyledPaymentDetailSummaryEditorialFormInputWithError>
                </StyledPaymentDetailSummaryEditorialFormBlock>
                <StyledPaymentDetailSummaryEditorialFormBlock>
                  <span>Paese di spedizione</span>
                  <StyledPaymentDetailSummaryEditorialFormInputWithError>
                    <StyledPaymentDetailChooserItemFilterInput>
                      <StyledFormSelect>
                        <select
                          defaultValue={formData.country}
                          required
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              country: e.currentTarget.value, 
                            });
                            let newFormError = { ...formError };
                            newFormError.address.country = null;
                            setFormError(newFormError);
                          }}
                        >
                          <option value=''>-- Lista paesi --</option>
                          {countries.map((country, index) => (
                            <option key={index} value={country}>
                              {country}
                            </option>
                          ))}
                        </select>
                      </StyledFormSelect>
                    </StyledPaymentDetailChooserItemFilterInput>
                    {formError.address && formError.address.country && formError.address.country.map(
                      (error, index) => (
                        <span key={index} style={{ color: "red" }}>
                          {getErrorMessage(error)}
                        </span>))
                    }
                  </StyledPaymentDetailSummaryEditorialFormInputWithError>
                </StyledPaymentDetailSummaryEditorialFormBlock>
                {skipAddressCheck && (
                  <StyledPaymentDetailChoosersHiveFilterCheckbox
                    onClick={() => {
                      let newFormError = { ...formError };
                      newFormError.address = {};
                      setFormError(newFormError);
                      setFormData({
                        ...formData,
                        force: !formData.force, 
                      });
                    }}
                  >
                    Ignora i suggerimenti sull`indirizzo
                    {formData.force && (
                      <Icon icon={icons.check} color={Colors.yellow} />
                    )}
                  </StyledPaymentDetailChoosersHiveFilterCheckbox>
                )}
              </> : null}
            <p>
              <StyledPaymentDetailSummaryEditorialFormAnchor
                onClick={() => setUserExist(true)}
              >
                Hai già un account? Vai al Login
              </StyledPaymentDetailSummaryEditorialFormAnchor>
            </p>
          </form>
        </StyledPaymentDetailSummaryEditorialForm>
      </StyledPaymentDetailSummaryEditorial>
      <StyledPaymentDetailChooserCTAButtons>
        <StyledPaymentDetailChooserCTAButton
          onClick={performSignup}
        >
          Prosegui{" "}
          <Icon icon={icons.arrowRight} color={Colors.black} />
        </StyledPaymentDetailChooserCTAButton>
      </StyledPaymentDetailChooserCTAButtons>
    </>
  );
};
